'use strict';

function audioPlayer($containers){

	var getNextRate = function(actualRate){
		
		var playbackRates = [0.5, 1 , 1.5, 2];
		var actualIndex = playbackRates.indexOf(actualRate);

		if(actualIndex > -1){
			return playbackRates.length-1 > actualIndex?playbackRates[actualIndex+1]:playbackRates[0];
		}

		return actualRate;
	}


	var convertTime = function (input) {
		
		input = Math.round(input);
		
	    var pad = function(input) {return input < 10 ? "0" + input : input;};
	    var arr = [];
	    if(Math.floor(input / 3600)>0){
	    	arr.push(pad(Math.floor(input / 3600)));
	    }
	    arr.push(pad(Math.floor(input % 3600 / 60)));
	    arr.push(pad(Math.floor(input % 60)));

	    return arr.join(':');
	};


	$containers.each(function(){

		var $container = $(this);
		
		var actualRate = 1;
		var volume = 1;

		var $timeCur = $(".auplayer__time div:first-child", $container);
		var $timeMax = $(".auplayer__time div:last-child", $container);

		var $playpause = $(".auplayer__playpause", $container);
		var $rate = $(".auplayer__rate", $container);
		var $back = $(".auplayer__back", $container);
		var $fwd = $(".auplayer__fwd", $container);
		var $seek  = $(".auplayer__seekbar .rangeslider", $container);
		var $mute  = $(".auplayer__mute", $container);
		var $vol  = $(".auplayer__volume .rangeslider", $container);

		var audio = $( 'audio', $container )[0];
		audio.type = "audio/aac";
		audio.volume = volume;

		var processDuration = function(duration){
			if($seek[0]){
				$timeMax.html(convertTime(duration));
				$seek.css('--max', duration);
				$seek[0].max = duration;
			}
		};
		
		
		// helper for tracking
		var initialOpenDone = false;
		var firstPausedDone = false;
		var firstPlayedDone = false;
		var contentFinished = false;
		
		



		$rate.on('click', function(){

			var nextRate = getNextRate(actualRate);
			audio.playbackRate = nextRate;
			actualRate = nextRate;
			$rate.html(actualRate + 'x');
			ga4Tracking("change_speed_clicked");
		});

		$playpause.on('click', function(){
			if (!audio.paused) {
				ga4Tracking("pause_clicked");
				firstPausedDone = true
				audio.pause();
			} else {
				if (firstPausedDone){
					ga4Tracking("resume_clicked");
				}
				audio.play();
			}
		});


		audio.onpause = function(){
			$playpause.addClass("auplayer__playpause--paused");
		};

		audio.onplay = function(){
			$playpause.removeClass("auplayer__playpause--paused");
			if (!firstPlayedDone){
				ga4Tracking("audio_content_started");
			}
			firstPlayedDone = true;
		};
		
		audio.ontimeupdate = function(){
			if(!contentFinished){
				if(audio.currentTime/audio.duration > 0.97){
					ga4Tracking("audio_content_ended");
					contentFinished = true;
				}
			}
		}

		$back.on('click', function(){
			audio.currentTime = (audio.currentTime - 10);
			ga4Tracking("rewind_clicked");
		});

		$fwd.on('click', function(){
			audio.currentTime = (audio.currentTime + 10);
			ga4Tracking("fast_forward_clicked");
		});
		
		
		var timeoutSeekTrack;
		
		$seek.on('input', function(){
			audio.currentTime = $seek.val();
			
			clearTimeout(timeoutSeekTrack);
			
			timeoutSeekTrack = setTimeout(function(){
				ga4Tracking("slider_dragged");
			}, 500);
		});
		


		$mute.on('click', function(){
			if (audio.muted) {
				audio.muted = false;
				$mute.removeClass("auplayer__mute--muted");
			} else {
				audio.muted = true;
				$mute.addClass("auplayer__mute--muted");
			}
		});


		if(audio.duration){
			processDuration(audio.duration);
		}else{
			audio.onloadedmetadata = function() {
				processDuration(audio.duration);
			};
		}


		if($seek[0]){
			$seek[0].value = 0;
			$seek.css('--value', 0);
		}


		if( !($container.data("audio") && $container.data("audio").type == "livestream") ){
			
			audio.addEventListener('timeupdate',function() {
				//var curtime = parseInt(audio.currentTime,10);
				
				if($seek[0]){
					var curtime = audio.currentTime;
					$timeCur.html(convertTime(curtime));
					$seek.css('--value', curtime);
					$seek[0].value = curtime;
				}
			});
		
		}

		$vol.val(volume);
		$vol.css('--value', volume);

		$vol.on('input', function(){
			audio.volume = $vol.val();
		});


		$container[0].addEventListener("articleAudioPlay", function(e){

			audio.play();
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'inline',
				'eventaction': 'listen to article',
				'eventlabel': $("audio source",$container).attr("src")
			});
			
			dataLayer.push({
				'event' : 'ga4_event',
				'eventcategory' : 'inline',
				'eventaction' : 'listen to article',
				'eventlabel' : $("audio source",$container).attr("src")
			});
			
			if(!initialOpenDone){
				ga4Tracking("opened");
				initialOpenDone = true;
			}

		});
		
		
		
		$container[0].addEventListener("articleAudioClose", function(e){
			audio.pause();
			ga4Tracking("closed");
			if(audio.currentTime<audio.duration){
				ga4Tracking("audio_content_aborted_and_closed");
			}
		});
		
		
		
		var ga4Tracking = function(event_name){
			
			var data = {
	        		event: "ga4_event",
	        		event_name: event_name,
	        		media_type: $container.data("audio")?$container.data("audio").mediaType:"",
	        		media_view_mode: "not_available",
	        		media_headline: $container.data("audio")?$container.data("audio").title:"",
	        		media_id: $container.data("audio")?$container.data("audio").id:"",
	        		media_editorial_unit: $container.data("audio")?$container.data("audio").section:"",
	        };
			
			if(event_name==='change_speed_clicked' || event_name==='audio_content_started' || event_name==='audio_content_aborted_and_closed' || event_name==='audio_content_ended'){
				data.media_speed = actualRate;
			}else{
				data.media_speed = "not_available";
			}
			
			if(event_name==='audio_content_started'){
				data.media_length =  $container.data("audio") && $container.data("audio").type == "livestream"?"not_available":Math.round(audio.duration);
			}
			
			if(event_name==='audio_content_aborted_and_closed' || event_name==='audio_content_ended'){
				data.media_time_spent = Math.round(audio.currentTime);
			}

			ntvDebug("*** tracking Audioplayer ***");
			ntvDebug(data);
			
			// vorerst tracking nur für diese events live
			if(event_name==='audio_content_started' || event_name==='audio_content_aborted_and_closed' || event_name==='audio_content_ended'){
				dataLayer.push(data);
			}else{
				if(ntv.urlParams.testTrackAudio){
	        		dataLayer.push(data);
	        	}
	        }
			
		};

	});


}